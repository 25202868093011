import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../api';
import type ParentModel from '../model';
import type { ProtocolVO } from '../type';

export default class EditProtocolModel {
  constructor(parentModel: ParentModel) {
    this.parentModel = parentModel;
  }

  @observable visible = false;
  @observable parentModel: ParentModel = null;
  @observable form: FormInstance = null;
  @observable type: '添加' | '编辑' = '添加';
  @observable row: ProtocolVO = null;

  public typeDict = [
    { label: '页面', value: 1 },
    { label: '链接', value: 2 },
    { label: '弹窗', value: 3 },
  ];
  public terminalDict = [
    { label: '主播端', value: 1 },
    { label: '雇主端', value: 2 },
  ];

  @action handleOpen = (row?: ProtocolVO) => {
    if (row) {
      this.type = '编辑';
      this.row = row;
      setTimeout(() => this.form.setFieldsValue(row));
    }
    this.visible = true;
  };
  @action handleClose = () => {
    this.visible = false;
    this.form.resetFields();
    this.parentModel.refresh();
  };
  @action setForm = (ref: FormInstance) => {
    this.form = ref;
  };
  public confirm = async () => {
    await this.form.validateFields();
    const url = this.type === '编辑' ? API.protocolUpdate : API.protocolAdd;
    const postParams = { ...this.form.getFieldsValue(), id: this.row?.id };
    const res = await request<BaseData<boolean>>({
      url,
      method: 'POST',
      data: {
        ...postParams,
      },
    });
    if (res.data) {
      message.success('编辑成功');
    }
    this.handleClose();
  };
}
