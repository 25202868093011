import RichText from '@/compnents/richText';
import { Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import type EditProtocolModel from './editProtocolModel';

const EditProtocol = ({ store }: { store: EditProtocolModel }) => {
  return (
    <Modal
      title={store.type}
      open={store.visible}
      onCancel={store.handleClose}
      maskClosable={true}
      width={800}
      okButtonProps={{ className: 'mr-16' }}
      onOk={store.confirm}
    >
      <Form
        ref={store.setForm}
        labelCol={{ span: 4 }}
        layout="horizontal"
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="协议标题"
          name="title"
          rules={[{ required: true, max: 20 }]}
        >
          <Input className="w-50" />
        </Form.Item>
        {store.type === '添加' ? (
          <>
            <Form.Item
              label="协议类型"
              name="type"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: 200 }}
                options={store.typeDict}
              />
            </Form.Item>
            <Form.Item
              label="终端"
              name="terminal"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: 200 }}
                options={store.terminalDict}
              />
            </Form.Item>
          </>
        ) : null}

        <Form.Item
          label="协议内容"
          name="content"
          rules={[{ required: true }]}
        >
          <RichText />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default observer(EditProtocol);
